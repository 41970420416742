import * as React from "react";

import Layout from "../../components/layout";
import ExternalLink from "../../components/external-link";
import "./style.css";

import MusicImg from "./img/music.png"

const MusicPage = () => {
  return (
    <Layout title="music | 🧃" bgColor="#eddfdf">
      <div id="music-page">
        <div className="left-60">
          <h1 className="big-text">music bytes 🎶</h1>
          <p>
            <ul className="bytes-list">
                <li><strong><a href="/music/bts">bts</a></strong>: bbs :")</li>
                <li>
                <strong><ExternalLink
                  url="https://gracejiang.github.io/mandopop/"
                  content="mandopop"
                /></strong>{" "}
                (dec 2020): site about mandarin pop music &amp; its construction
                on gender
                </li>
            </ul>
          </p>
        </div>
        <div className="right-35">
            <img src={MusicImg} />
        </div>
      </div>
    </Layout>
  );
};

export default MusicPage;
